@font-face {
  font-family: "HurmeDesign";
  src: local("HurmeDesign"),
       url("./assets/fonts/hurme.ttf") format("truetype");
  font-weight: normal;
}

.App {
  text-align: center;
  color: #e30613;
}

.App-logo {
  height: 40vmin;
  max-height: 200px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html, body, #root {
  /* font: 0.9rem sans-serif; */
  font-family: "HurmeDesign", sans-serif;
  font-size: calc(11px + (26 - 11) * ((100vw - 300px) / (1600 - 300)));
  background: #ffffff;
  /* background: linear-gradient(110deg, #FFFFFF, #dddddd 35%, #ffffff 50%, #eeeeee 50%, #dddddd);
  background-attachment: fixed; */
  color: #1e2432;
  height: 100%;
  width: 100%;
  margin: 0;
  /* apply a natural box layout model to all elements, but allowing components to change */
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.main-container {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.navigation-pane {
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  margin-top: 0px;
  padding: 12px 8px 8px;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.navigation-pane > .MuiGrid-container {
  max-width: 440px;
  margin-left: auto;
  margin-right: auto;
}

.content-pane {
  /* background: rgba(255,255,255,0.70); */
  width: 100%;
  border: none;
}

.content-pane.content-pane-green {
  background: rgb(174,213,163);
  background: linear-gradient(180deg, rgba(174,213,163,1) 0%, rgba(125,184,90,1) 50%, rgba(129,186,96,1) 100%);
  padding-top: 90px;
  min-height: 100vh;
  border: none;
}

.content-pane.content-pane-green > .MuiGrid-container {
  max-width: 440px;
  margin: auto;
}

.content-pane.content-pane-green .title_heading {
  display: inline-flex;
  justify-content: space-between;
  width: calc(100% - 50px);
  margin-left: 15px;
  margin-right: 15px;
}

.content-pane.content-pane-green .title_heading h1 {
  color: #ffffff;
  width: 100%;
  margin: 6px auto;
  padding: 4px;
  font-size: 24px;
  text-align: left;
  font-weight: bold;
}

.content-pane.content-pane-green .title_heading svg {
  color: #fff;
  font-size: 30px;
  margin-top: 11px;
  margin-right: 0;
}

.content-pane.content-pane-green h2 {
  color: #ffffff;
  width: calc(100% - 50px);
  margin: 6px auto;
  padding: 4px;
  font-size: 18px;
  text-align: left;
  font-weight: bold;
}

.content-pane.content-pane-green h3 {
  color: #ffffff;
  width: calc(100% - 50px);
  margin: 6px auto;
  padding: 4px;
  font-size: 16px;
  text-align: left;
  font-weight: bold;
}

.content-pane.content-pane-green h4 {
  color: #ffffff;
  width: calc(100% - 50px);
  margin: auto;
  padding: 4px;
  font-size: 14px;
  text-align: left;
  font-weight: bold;
  line-height: 1.3; 
}

.content-pane.content-pane-green p {
  color: #ffffff;
  width: calc(100% - 50px);
  margin: auto;
  padding: 4px;
  font-size: 14px;
  text-align: left;
  font-weight: bold;
  line-height: 1.3;
}

.content-pane.content-pane-green form {
  width: 100%;
}

.content-pane.content-pane-green form .form-field-label {
  color: #ffffff;
  width: calc(100% - 50px);
  margin: auto;
  padding: 4px;
  font-size: 16px;
  text-align: left;
  font-weight: bold;
}

.content-pane.content-pane-green form .MuiInputBase-formControl {
  color: #92959e;
  width: calc(100% - 30px);
  border: none;
  height: 60px;
  outline: none;
  font-size: 16px;
  max-width: 440px;
  box-shadow: 0px 4px 0px 0px #7eab69 inset;
  text-align: left;
  font-family: HurmeDesign;
  font-weight: normal;
  line-height: 1;
  margin-left: auto;
  padding-top: 4px;
  margin-right: auto;
  border-radius: 14px;
  margin-bottom: 6px;
  background-color: #ffffff;
  background-image: none !important;
}

.content-pane.content-pane-green form .MuiSelect-select {
  color: #92959e;
  width: calc(100% - 30px);
  height: 60px;
  max-width: 440px;
  max-height: 50px;
  font-size: 16px;
  box-shadow: 0px 4px 0px 0px #7eab69 inset;
  text-align: center;
  font-family: HurmeDesign;
  font-weight: normal;
  line-height: 3;
  border-radius: 14px;
  padding-top: 4px;
  margin-right: 15px;
  margin-left: 15px;
  margin-bottom: 0px;
  background-color: #ffffff;
  background-image: none !important;
}

.content-pane.content-pane-green form .MuiSelect-icon {
  margin-right: 30px;
  font-size: 30px;
}

.content-pane.content-pane-green form .MuiInputBase-formControl input,
.content-pane.content-pane-green form .MuiInputBase-formControl fieldset {
  outline: none;
  border-color: transparent;
}

.content-pane.content-pane-green form [class*="makeStyles-checkbox_field-"] .Mui-checked {
}

.gradient-bg {
  background: linear-gradient(110deg, #FFFFFF, #dddddd 35%, #eeeeee 60%, #dddddd 20%, #eeeeee);
}

/* Override MUI styling */
.MuiButton-contained {
  color: #535353 !important;
  font-family: "HurmeDesign", sans-serif !important;
  font-size: 18px !important;
  text-transform: none !important;
  line-height: 1 !important;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(216,216,216,1) 100%);
  border: 1px solid #dddddd !important;
  border-radius: 10px !important;
  width: calc(100% - 30px);
  max-width: 440px;
  padding-top: 30px !important;
  padding-bottom: 30px !important;
  margin-bottom: 15px !important;
  box-shadow: 0px 5px 0px 0px rgb(0 0 0 / 10%), 0px 2px 2px 0px rgb(0 0 0 / 10%), 0px 1px 5px 0px rgb(0 0 0 / 10%) !important;
}

.MuiButton-containedPrimary {
  color: #ffffff !important;
  font-family: "HurmeDesign", sans-serif !important;
  font-size: 18px !important;
  line-height: 1 !important;
  background: rgb(12,214,254);
  background: linear-gradient(180deg, rgba(12,214,254,1) 0%, rgba(6,150,255,1) 100%);
  border: 1px solid #fff !important;
  border-top-width: 2.5px !important;
  border-radius: 10px !important;
  width: calc(100% - 30px);
  padding-top: 30px !important;
  padding-bottom: 30px !important;
  box-shadow: 0px 5px 0px 0px rgb(0 0 0 / 10%), 0px 2px 2px 0px rgb(0 0 0 / 10%), 0px 1px 5px 0px rgb(0 0 0 / 10%) !important;
}

.MuiButton-outlinedPrimary {
  color: #575757 !important;
  font-family: "HurmeDesign", sans-serif !important;
  font-size: 15px !important;
  line-height: 1 !important;
  background: rgb(214,214,214);
  background: linear-gradient(0deg, rgb(204, 204, 204) 5%, rgba(245,245,245,1) 100%) !important;
  border: 1px solid #9f9e9e !important;
  border-radius: 10px !important;
  width: calc(100% - 30px);
  padding-top: 30px !important;
  padding-bottom: 30px !important;
  margin-bottom: 15px !important;
  box-shadow: 2px 5px 5px 0px rgb(0 0 0 / 10%) !important;
}

.MuiButton-containedSecondary {
  color: #ffffff !important;
  font-family: "HurmeDesign", sans-serif !important;
  font-size: 18px !important;
  line-height: 1 !important;
  background: rgb(174,213,163);
  background: linear-gradient(180deg, rgba(174,213,163,1) 0%, rgba(125,184,90,1) 100%);
  border: 1px solid #fff !important;
  border-top-width: 2.5px !important;
  border-radius: 10px !important;
  width: calc(100% - 30px);
  padding-top: 30px !important;
  padding-bottom: 30px !important;
  box-shadow: 0px 5px 0px 0px rgb(0 0 0 / 10%), 0px 2px 2px 0px rgb(0 0 0 / 10%), 0px 1px 5px 0px rgb(0 0 0 / 10%) !important;
}

.MuiButton-contained.clear {
  color: #ffffff !important;
  font-family: "HurmeDesign", sans-serif !important;
  font-size: 18px !important;
  line-height: 1 !important;
  background: rgb(125,184,90);
  background: linear-gradient(0deg, rgba(125,184,90,1) 0%, rgba(174,213,163,1) 100%);
  border: 1px solid #fff !important;
  border-top-width: 2.5px !important;
  border-radius: 10px !important;
  width: calc(100% - 30px);
  padding-top: 30px !important;
  padding-bottom: 30px !important;
  box-shadow: 0px 5px 0px 0px rgb(0 0 0 / 10%), 0px 2px 2px 0px rgb(0 0 0 / 10%), 0px 1px 5px 0px rgb(0 0 0 / 10%) !important;  
}

.MuiButtonBase-root.button_danger,
.MuiButtonBase-root[class*="makeStyles-button_danger-"] {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
  padding-left: 20px;
  text-align: left;
  justify-content: center;
  background: rgb(255,77,0,87);
  background: linear-gradient(180deg, rgba(255,77,87,1) 0%, rgba(243,22,35,1) 100%);
}

.MuiButtonBase-root[class*="makeStyles-button_small-"] {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.MuiFab-circular {
  background: #9bca86;
  color: #ffffff;
  box-shadow: 0px 5px 0px 0px rgb(0 0 0 / 10%), 0px 2px 2px 0px rgb(0 0 0 / 10%), 0px 1px 5px 0px rgb(0 0 0 / 10%) !important;
  width: 70px;
  height: 70px;
  margin: 20px 20px 20px 30px;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: none;
}

[class*="makeStyles-form_field_time-"]:before,
[class*="makeStyles-form_field_time-"]:after {
  border-bottom: 0px !important;
}

[class*="makeStyles-form_field_time-"] .MuiSelect-icon {
  top: calc(50% - 8px);
  right: 5px;
  height: 18px;
}

.MuiSelect-filled {
  color: #ffffff;
  line-height: 1;
  font-family: HurmeDesign;
  font-size: 30px;
  font-weight: normal;
  border: none !important;
  border-bottom: none !important;
  height: 100%;
  textAlign: left;
}